<template>
  <main class="mr-4 md:mr-0">
    <trac-loading v-if="loading"></trac-loading>

    <div class="" v-if="status.length > 0">
      <div
        class="flex justify-center flex-col bg-white rounded-md mt-12 p-5 items-center"
      >
        <h1 class="mt-10 text-primaryBlue font-medium">
          Complete KYC by verifying the following.
        </h1>

        
        <div
          class="grid grid-cols-1 md:grid-cols-3 gap-10 w-full max-w-3xl py-20"
        >
          <!-- Business Profile -->
          <div
            @click="verificationProcessGuard('businessProfileCheck')"
            v-if="!isCustomKycRoute"
            class="p-5 rounded h-48 flex flex-col justify-center relative items-center bg-accentLight cursor-pointer"
          >
          <img
            class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
            v-if="streetAddress"
            src="@/assets/svg/kyc-check-green.svg"
            alt=""
          />
            <svg
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 mt-2"
            >
              <path
                d="M7.91667 12.4993H2.91667C2.68667 12.4993 2.5 12.3127 2.5 12.0827C2.5 11.8527 2.68667 11.666 2.91667 11.666H7.91667C8.14667 11.666 8.33333 11.8527 8.33333 12.0827C8.33333 12.3127 8.14667 12.4993 7.91667 12.4993Z"
                fill="#253B95"
              ></path>
              <path
                d="M7.08333 14.9993H2.91667C2.68667 14.9993 2.5 14.8127 2.5 14.5827C2.5 14.3527 2.68667 14.166 2.91667 14.166H7.08333C7.31333 14.166 7.5 14.3527 7.5 14.5827C7.5 14.8127 7.31333 14.9993 7.08333 14.9993Z"
                fill="#253B95"
              ></path>
              <path
                d="M7.08268 5.83333C6.16352 5.83333 5.41602 5.08583 5.41602 4.16667C5.41602 3.2475 6.16352 2.5 7.08268 2.5C8.00185 2.5 8.74935 3.2475 8.74935 4.16667C8.74935 5.08583 8.00185 5.83333 7.08268 5.83333ZM7.08268 3.33333C6.62352 3.33333 6.24935 3.7075 6.24935 4.16667C6.24935 4.62583 6.62352 5 7.08268 5C7.54185 5 7.91602 4.62583 7.91602 4.16667C7.91602 3.7075 7.54185 3.33333 7.08268 3.33333Z"
                fill="#253B95"
              ></path>
              <path
                d="M10 9.99935C9.77 9.99935 9.58333 9.81268 9.58333 9.58268V8.74935C9.58333 8.06018 9.0225 7.49935 8.33333 7.49935H5.83333C5.14417 7.49935 4.58333 8.06018 4.58333 8.74935V9.58268C4.58333 9.81268 4.39667 9.99935 4.16667 9.99935C3.93667 9.99935 3.75 9.81268 3.75 9.58268V8.74935C3.75 7.60102 4.685 6.66602 5.83333 6.66602H8.33333C9.48166 6.66602 10.4167 7.60102 10.4167 8.74935V9.58268C10.4167 9.81268 10.23 9.99935 10 9.99935Z"
                fill="#253B95"
              ></path>
              <path
                d="M7.91667 17.5H2.08333C0.935 17.5 0 16.565 0 15.4167V2.08333C0 0.935 0.935 0 2.08333 0H12.0833C13.2317 0 14.1667 0.935 14.1667 2.08333V7.13333C14.1667 7.36333 13.98 7.55 13.75 7.55C13.52 7.55 13.3333 7.36333 13.3333 7.13333V2.08333C13.3333 1.39417 12.7725 0.833333 12.0833 0.833333H2.08333C1.39417 0.833333 0.833333 1.39417 0.833333 2.08333V15.4167C0.833333 16.1058 1.39417 16.6667 2.08333 16.6667H7.91667C8.14667 16.6667 8.33333 16.8533 8.33333 17.0833C8.33333 17.3133 8.14667 17.5 7.91667 17.5Z"
                fill="#253B95"
              ></path>
              <path
                d="M14.5827 19.9993C11.596 19.9993 9.16602 17.5693 9.16602 14.5827C9.16602 11.596 11.596 9.16602 14.5827 9.16602C17.5693 9.16602 19.9993 11.596 19.9993 14.5827C19.9993 17.5693 17.5693 19.9993 14.5827 19.9993ZM14.5827 9.99935C12.0552 9.99935 9.99935 12.0552 9.99935 14.5827C9.99935 17.1102 12.0552 19.166 14.5827 19.166C17.1102 19.166 19.166 17.1102 19.166 14.5827C19.166 12.0552 17.1102 9.99935 14.5827 9.99935Z"
                fill="#253B95"
              ></path>
              <path
                d="M13.7496 16.6662C13.6396 16.6662 13.5329 16.622 13.4546 16.5445L11.7879 14.8779C11.6254 14.7154 11.6254 14.4512 11.7879 14.2887C11.9504 14.1262 12.2146 14.1262 12.3771 14.2887L13.7287 15.6404L16.3521 12.642C16.5037 12.4679 16.7671 12.4504 16.9404 12.6029C17.1137 12.7545 17.1312 13.0179 16.9796 13.1912L14.0629 16.5245C13.9871 16.6112 13.8779 16.6629 13.7629 16.6662C13.7587 16.6662 13.7537 16.6662 13.7496 16.6662Z"
                fill="#253B95"
              ></path>
            </svg>
            <p class="text-sm mt-10 font-medium text-center text-primaryBlue">
              Business Profile
            </p>
          </div>

          <!-- Verify Contact Details -->
          <div
            @click="verificationProcessGuard('contactCheck')"
            class="p-5 rounded h-48 flex flex-col justify-center relative items-center bg-accentLight cursor-pointer"
          >
            <img
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              v-if="phone.status === 'VERIFIED' && email.status === 'VERIFIED'"
              src="@/assets/svg/kyc-check-green.svg"
              alt=""
            />

            <svg
              class="w-10"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.65234 17.179V37.8608H14.3469V28.8125V28.8125C14.3469 25.9569 16.5172 23.642 19.1942 23.642C21.8713 23.642 24.0415 25.9569 24.0415 28.8125V28.8125V37.8608H33.7362V17.179"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.01758 37.8605H37.3723"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.27266 13.4369H1.27266C0.673885 14.7138 1.15896 16.2668 2.35611 16.9055C2.69295 17.0852 3.06441 17.1787 3.44102 17.1786H34.9485C36.287 17.179 37.3725 16.0219 37.3729 14.5941C37.373 14.1924 37.2853 13.7962 37.1168 13.4369L32.5248 6.83765H5.86467L1.27266 13.4369Z"
                fill="#B4E6FF"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.86426 6.83769V4.25246L5.86426 4.25246C5.86426 2.82468 6.94936 1.66724 8.28791 1.66724H30.1008V1.66724C31.4393 1.66724 32.5244 2.82468 32.5244 4.25246C32.5244 4.25246 32.5244 4.25246 32.5244 4.25246V6.83769"
                stroke="#003283"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="text-sm mt-10 font-medium text-primaryBlue">
              Verify contact details
            </p>
          </div>

          <!-- Verify BVN -->
          <div
            @click="verificationProcessGuard('bvnCheck')"
            class="p-5 rounded h-48 flex flex-col relative justify-center items-center bg-accentLight cursor-pointer"
          >
            <svg
              v-if="bvn.status === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-if="bvn.status === 'PENDING'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#FBD56F" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="flex-1"></div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.9965 16H16.0054"
                stroke="#253B95"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9955 16H12.0045"
                stroke="#253B95"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99451 16H8.00349"
                stroke="#253B95"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="flex-1"></div>

            <p class="text-sm font-medium text-center text-primaryBlue">
              Verify BVN
            </p>
            <div class="outcome text-center flex flex-row">
              <div class="text-red-600 mt-1 font-medium" v-if="bvnFailed">
                Verification failed. Click to retry
              </div>
              <div v-if="bvnPending">Undergoing Verification</div>
            </div>

            <p
              class="text-sm text-primaryBlue capitalize"
              v-if="bvn.status === 'VERIFIED'"
            >
              {{ bvn.message }}
            </p>
            <p>
              <span class="text-xs" v-if="bvn.status === 'OVERRIDE'">
                OVERRIDE
              </span>
            </p>
          </div>

          <!-- Verify Identity -->
          <div
            @click="verificationProcessGuard('identityCheck')"
            class="p-5 rounded h-48 flex flex-col relative justify-center items-center bg-accentLight cursor-pointer"
          >
          <div class="flex-1"></div>
            <svg
              v-if="document.status === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-if="document.status === 'PENDING'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#FBD56F" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_249_22858)">
                <path
                  d="M21.2499 20.75H2.75006C1.3711 20.75 0.25 19.6289 0.25 18.2499V5.75006C0.25 4.3711 1.3711 3.25 2.75006 3.25H21.2499C22.6289 3.25 23.75 4.3711 23.75 5.75006V18.2499C23.75 19.6289 22.6289 20.75 21.2499 20.75ZM2.75006 4.25C1.92296 4.25 1.25 4.92296 1.25 5.75006V18.2499C1.25 19.077 1.92296 19.75 2.75006 19.75H21.2499C22.077 19.75 22.75 19.077 22.75 18.2499V5.75006C22.75 4.92296 22.077 4.25 21.2499 4.25H2.75006Z"
                  stroke="#253B95"
                  stroke-width="0.5"
                />
                <path
                  d="M7.49994 11.7501C6.25997 11.7501 5.25 10.7399 5.25 9.50012C5.25 8.26012 6.25998 7.25 7.49994 7.25C8.7399 7.25 9.74988 8.26012 9.74988 9.50012C9.74988 10.7399 8.73991 11.7501 7.49994 11.7501ZM7.49994 8.25C6.81087 8.25 6.25 8.81093 6.25 9.50012C6.25 10.1892 6.8109 10.7501 7.49994 10.7501C8.18897 10.7501 8.74988 10.1892 8.74988 9.50012C8.74988 8.81093 8.189 8.25 7.49994 8.25Z"
                  stroke="#253B95"
                  stroke-width="0.5"
                />
                <path
                  d="M11.25 16.7501C10.9741 16.7501 10.75 16.5261 10.75 16.2501V15.7501C10.75 14.923 10.077 14.25 9.24994 14.25H5.75006C4.92296 14.25 4.25 14.923 4.25 15.7501V16.2501C4.25 16.5261 4.02593 16.7501 3.75 16.7501C3.47407 16.7501 3.25 16.5261 3.25 16.2501V15.7501C3.25 14.3711 4.3711 13.25 5.75006 13.25H9.24994C10.6289 13.25 11.75 14.3711 11.75 15.7501V16.2501C11.75 16.5261 11.5259 16.7501 11.25 16.7501Z"
                  stroke="#253B95"
                  stroke-width="0.5"
                />
                <path
                  d="M20.2499 8.75H14.75C14.4741 8.75 14.25 8.52593 14.25 8.25C14.25 7.97407 14.4741 7.75 14.75 7.75H20.2499C20.5259 7.75 20.7499 7.97407 20.7499 8.25C20.7499 8.52593 20.5259 8.75 20.2499 8.75Z"
                  stroke="#253B95"
                  stroke-width="0.5"
                />
                <path
                  d="M20.2499 12.75H14.75C14.4741 12.75 14.25 12.5259 14.25 12.25C14.25 11.9741 14.4741 11.75 14.75 11.75H20.2499C20.5259 11.75 20.7499 11.9741 20.7499 12.25C20.7499 12.5259 20.5259 12.75 20.2499 12.75Z"
                  stroke="#253B95"
                  stroke-width="0.5"
                />
                <path
                  d="M20.2499 16.75H14.75C14.4741 16.75 14.25 16.5259 14.25 16.25C14.25 15.9741 14.4741 15.75 14.75 15.75H20.2499C20.5259 15.75 20.7499 15.9741 20.7499 16.25C20.7499 16.5259 20.5259 16.75 20.2499 16.75Z"
                  stroke="#253B95"
                  stroke-width="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_249_22858">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div class="flex-1"></div>
            <p class="text-sm font-medium text-center text-primaryBlue">
              Verify Identity
            </p>

            <div class="outcome text-center flex flex-row">
              <div v-if="identityFailed" class="text-red-600 mt-1 font-medium">
                Verification failed. Click to retry
              </div>
              <div v-if="identityPending">Undergoing Verification</div>
            </div>

            <p
              class="text-sm text-primaryBlue capitalize"
              v-if="document && document.types && document.types.length"
            >
              {{ document.message }}
            </p>
            <p>
              <span class="text-xs" v-if="document.status === 'OVERRIDE'">
                OVERRIDE
              </span>
            </p>
          </div>

          <!-- Address Verification -->
          <div
            @click="verificationProcessGuard('addressCheck')"
            class="p-5 rounded h-48 flex flex-col justify-center items-center relative bg-accentLight cursor-pointer"
          >
            <svg
              v-if="address.status === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              class="w-8 mt-2"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50065 14.1663H5.00065C4.45348 14.1663 3.91166 14.0586 3.40614 13.8492C2.90061 13.6398 2.44128 13.3329 2.05437 12.946C1.27297 12.1646 0.833984 11.1047 0.833984 9.99967C0.833984 8.89461 1.27297 7.8348 2.05437 7.0534C2.83577 6.27199 3.89558 5.83301 5.00065 5.83301H7.50065M12.5006 5.83301H15.0006C15.5478 5.83301 16.0896 5.94078 16.5952 6.15018C17.1007 6.35957 17.56 6.66649 17.9469 7.0534C18.3338 7.44031 18.6408 7.89964 18.8501 8.40516C19.0595 8.91068 19.1673 9.4525 19.1673 9.99967C19.1673 10.5468 19.0595 11.0887 18.8501 11.5942C18.6408 12.0997 18.3338 12.559 17.9469 12.946C17.56 13.3329 17.1007 13.6398 16.5952 13.8492C16.0896 14.0586 15.5478 14.1663 15.0006 14.1663H12.5006V5.83301Z"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66602 10H13.3327"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="text-sm mt-10 font-medium text-center text-primaryBlue">
              Address Verification
            </p>
            <div class="outcome text-center flex flex-row">
              <div v-if="addressFailed" class="text-red-600 mt-1 font-medium">
                Verification failed. Click to retry
              </div>
              <div v-if="addressPending">PENDING</div>
            </div>
          </div>

          <!-- Settlement Preference -->
          <div
            @click="verificationProcessGuard('settlementCheck')"
            v-if="!isCustomKycRoute"
            class="p-5 rounded h-48 flex flex-col justify-center items-center relative bg-accentLight cursor-pointer"
          >
            <svg
              v-if="account.status === 'VERIFIED'"
              class="w-4 h-4 absolute top-0 right-0 -mt-1 -mr-1"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.2834" cy="10.2834" r="10.2834" fill="#41CC68" />
              <path
                d="M15.0866 7.47949L8.57101 13.9951L5.60938 11.0335"
                stroke="white"
                stroke-width="1.8894"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              class="w-8 mt-2"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50065 14.1663H5.00065C4.45348 14.1663 3.91166 14.0586 3.40614 13.8492C2.90061 13.6398 2.44128 13.3329 2.05437 12.946C1.27297 12.1646 0.833984 11.1047 0.833984 9.99967C0.833984 8.89461 1.27297 7.8348 2.05437 7.0534C2.83577 6.27199 3.89558 5.83301 5.00065 5.83301H7.50065M12.5006 5.83301H15.0006C15.5478 5.83301 16.0896 5.94078 16.5952 6.15018C17.1007 6.35957 17.56 6.66649 17.9469 7.0534C18.3338 7.44031 18.6408 7.89964 18.8501 8.40516C19.0595 8.91068 19.1673 9.4525 19.1673 9.99967C19.1673 10.5468 19.0595 11.0887 18.8501 11.5942C18.6408 12.0997 18.3338 12.559 17.9469 12.946C17.56 13.3329 17.1007 13.6398 16.5952 13.8492C16.0896 14.0586 15.5478 14.1663 15.0006 14.1663H12.5006V5.83301Z"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66602 10H13.3327"
                stroke="#253B95"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p class="text-sm mt-10 font-medium text-center text-primaryBlue">
              Add settlement preference
            </p>
          </div>


          <trac-modal @close="pendingModal = false" v-if="pendingModal">
            <div class="flex flex-col justify-center items-center mb-10">
              <img class="w-40" src="@/assets/svg/pending.svg" alt="" />
              <p
                class="max-w-sm w-full text-sm font-medium text-center text-accentDark mt-4 leading-7"
              >
                Your KYC verification is currently being reviewed.
                <br />
              </p>
            </div>
          </trac-modal>
          <!-- <trac-modal
            :close="closeModal"
            v-if="
              $store.getters.GET_FROM_FIXED_ACCOUNT &&
                document.status === 'VERIFIED'
            "
          >
            <div class="flex flex-col justify-center items-center">
              <img class="w-64" src="@/assets/svg/fixed.svg" alt="" />
              <p
                class="max-w-sm w-full text-sm font-medium text-center text-accentDark mt-3 leading-7"
              >
                Would you like to create a fixed account
                <br />
                <trac-button class="mt-5 px-20 mb-5" @click.native="validate">
                  <span class="text-white cursor-pointer"
                    >Create Fixed Account
                  </span>
                </trac-button>
              </p>
            </div>
          </trac-modal> -->
        </div>
      </div>
    </div>
    <!-- Create a div with flex and right align a single button with not style -->
    <div class="flex justify-end pr-5">
      <div @click="gotoCorporate()" class="default-style flex gap-2 px-4 cursor-pointer items-center">
        <span v-if="corporatePending" class="p-2 smallest rounded-md text-white bg-orange-400">Pending</span>
        <span v-if="corporateDone" class="p-2 smallest rounded-md text-white bg-green-400">Verified</span>
        <span v-if="corporateFailed" class="p-2 smallest rounded-md text-white bg-red-400">Failed</span>
        <button class="py-5 smallest">Upgrade to a Corporate Account</button>
      </div>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_DATA } from '../../browser-db-config/localStorage';
import { eventBus } from "../../eventBus";
export default {
  data() {
    return {
      businessProfile: null,
      fetchedKycDetails: null,
      loading: false,
      isCustomKycRoute: false,
      walletDetails: null,
      pendingModal: false,
      status: [],
      document: {},
      account: {},
      email: {},
      phone: {},
      bvn: {},
      address: {},
      corporate: {}
    };
  },
  async created() {
    this.businessProfile = GET_USER_BUSINESS_DATA()
    this.loading = true;
    let result = await this.$store.dispatch("VERIFY_KYC");
    this.fetchedKycDetails = result.data || [];
    this.status = result.data;
    this.document = this.status.find(({ name }) => name === "document");
    this.account = this.status.find(({ name }) => name === "account");
    this.email = this.status.find(({ name }) => name === "email");
    this.phone = this.status.find(({ name }) => name === "phone");
    this.bvn = this.status.find(({ name }) => name === "bvn");
    this.address = this.status.find(({ name }) => name === "address");
    this.corporate = this.status.find(({ name }) => name === "corporate");
    this.isCustomKycRoute = this.$route.params.custom || false;
    this.loading = false;
    if (this.isFullyVerified()) {
      this.$SetPersonalAttribute({
        propName: "kyc_status",
        propValue: "true",
      });
    } else {
      this.$SetPersonalAttribute({
        propName: "kyc_status",
        propValue: "false",
      });
    }
    if (this.wasGivenOverride()) {
      this.$SetPersonalAttribute({
        propName: "kyc_overidden",
        propValue: "true",
      });
    } else {
      this.$SetPersonalAttribute({
        propName: "kyc_overidden",
        propValue: "false",
      });
    }
  },
  computed: {
    isAllowedCorporateVerification() {
      const list =  (this.fetchedKycDetails || []).filter(
        (rule) => rule.name !== "corporate"
      )
      // console.log('isAllowedCorporateVerification: ', list)
      return list.every(
        (rule) => rule.status === "VERIFIED" || rule.status === "OVERRIDE")
    },
    streetAddress() {
      const address = this.businessProfile ? this.businessProfile.address : {};
      return address.street || false;
    },
    corporatePending() {
      return this.corporate.status === 'PENDING';
    },
    corporateDone() {
      return this.corporate.status === 'VERIFIED';
    },
    corporateFailed() {
      return this.corporate.status === 'FAILED';
    },
    bvnFailed() {
      return this.bvn.status === 'FAILED';
    },
    bvnPending() {
      return this.bvn.status === 'PROCESSING';
    },
    addressPending () {
      return this.address.status === 'PENDING';
    },
    addressFailed () {
      return this.address.status === 'FAILED';
    },
    identityFailed() {
      return this.document.status === 'FAILED';
      },
    identityPending() {
      return this.document.status === 'PROCESSING';
    },
  },
  methods: {
    set(value) {
      return [...new Set(value)].join(" , ");
    },
    gotoCorporate() {
      // if(!this.isAllowedCorporateVerification) {
      //   eventBus.$emit('trac-alert', {
      //     message: 'Please complete previous tasks before creating a corporate account.'
      //   })
      // }
      if(this.corporatePending) {
        this.pendingModal = true;
        return false
      } 
      else if(this.corporateDone) {
        return false
      } 
      else {
        this.$router.push({ name: "CorporateAccount" });
      }
    },
    isFullyVerified() {
      const allExceptAccount = this.fetchedKycDetails.filter(
        (rule) => rule.name !== "account"
      );
      const isVerified = allExceptAccount.every((rule) => rule.status === "VERIFIED")
      return isVerified
    },
    wasGivenOverride() {
      return this.fetchedKycDetails.some((rule) => rule.status === "OVERRIDE");
    },
    verifyIdentity() {
      if (this.document.status === "PENDING") {
        this.pendingModal = true;
        return false
      } else if (this.document.status === 'VERIFIED') {
        return false
      } 
      else {
        return true
      }
    },
    async verificationProcessGuard(methodName = null){
      let toastMessage = []
      const checkList = []
      let currentCheck = null
      let continueScript = true

      const EmailAndNumberCheck = this.verifyAccount()
      const orderedCheckList = {
          businessProfileCheck : {
            status: true,
            name: 'Business Profile',
            routeDetails: {name: 'CompanyInfoDisabled'}
          },
          contactCheck : {
            status: true,
            name: 'Contact Details',
            routeDetails: EmailAndNumberCheck
          },
          bvnCheck : {
            status: true,
            name: 'BVN',
            routeDetails: {
              name: "VerifyBvn",
              query: {
                custom: this.isCustomKycRoute,
              }
            },
            extraFunction: 'checkifBvnIsPending'
          },
          identityCheck : {
            status: true,
            name: 'Identity',
            routeDetails: {
              name: "Photo",
              query: {
                custom: this.isCustomKycRoute,
              },
            },
            extraFunction: 'verifyIdentity'
          },
          addressCheck : {
            status: true,
            name: 'Address Verification',
            routeDetails: {
              name: 'UtilityBill',
            },
            extraFunction: 'checkifAddressIsPending'
          },
          settlementCheck : {
            status: false,
            name: 'Settlement Preference',
            routeDetails: {name : 'SettlementPreference'},
            extraFunction: 'gotoSettlementAccount'
          },
        }

        for (const key in orderedCheckList) {
          if (key === methodName) {
            currentCheck = orderedCheckList[key];
            break;
          }
          checkList.push(orderedCheckList[key]);
        }
        console.log('verificationGuardList: ', checkList)
        const allChecksPassed = checkList.every((check) => check.status === true);
        console.log('checkList: ', allChecksPassed)
        if (allChecksPassed) {
          if(currentCheck.extraFunction){
            continueScript = this[currentCheck.extraFunction]() instanceof Promise ? await this[currentCheck.extraFunction]() : this[currentCheck.extraFunction]()
            console.log('continueScript: ', continueScript)
          } 
          if (continueScript) {
            this.$router.push(currentCheck.routeDetails);
            console.log('verificationGuardList->routeDetails: ', currentCheck.routeDetails)
          }
        } else {
          toastMessage = checkList.filter((check) => check.status === false).map((check) => check.name);
          console.log('verificationGuardList->toastMessage: ', toastMessage)
          eventBus.$emit("trac-alert", {
            message: `To proceed, you will need to complete the previous steps: ${toastMessage.join(", ")}`
          });
        }
    
    },
    checkifBvnIsPending() {
      if (this.bvn.status === "PENDING") {
        this.pendingModal = true;
        return false
      } else if (this.bvn.status === 'VERIFIED') {
        return false
      } 
      else {
        return true
      }
    },
    checkifAddressIsPending() {
      if (this.address.status === "PENDING") {
        this.pendingModal = true;
        return false
      } else if (this.address.status === 'VERIFIED') {
        return false
      } 
      else {
        return true
      }
    },
    verifyAccount() {
      let context = {
        name: null,
        query: null
      }
      if (this.phone.status === "" && this.email.status === "") {
        context.name = "VerifyEmail",
        context.query = {
          custom: this.isCustomKycRoute,
        }
      } else if (this.phone.status === "" && this.email.status === "VERIFIED") {
        context.name = "VerifyPhone",
        context.query = {
          custom: this.isCustomKycRoute,
        }
      } else {
        context.name = "ExistingPhoneEmail",
        context.query = {
          custom: this.isCustomKycRoute,
        }
      }
      return context
    },
    settlementPreferenceGuardCheck() {
      const verified = this.wasGivenOverride() || this.isFullyVerified();
      if (!verified) {
        eventBus.$emit("trac-alert", {
          message: `To proceed, you will need to complete the previous steps`,
        });
      } else {
        this.gotoSettlementAccount();
      }
    },
    async gotoSettlementAccount() {
      this.loading = true;
      console.log('gotoSettlementAccount called. account.status ===', this.account.status)
      // await this.fetchWalletDetails();
      const alreadyVerified = this.account.status === "VERIFIED"
      // debugger
      if (alreadyVerified) {
        console.log('going to SettlementPreference')
        this.$router.push({ name: "SettlementPreference" });
      } else {
        console.log('going to AddBank Details')
        this.$router.push({
          name: "AddBankDetails",
          query: {
            // ops: '',
            onboarding: true,
          },
        });
      }
      this.loading = false;
      return false
    },
    async fetchWalletDetails() {
      // this.walletDetails = this.$store.getters["GET_WALLET_DETAILS"] || {})[
      //   "data"
      // ];

      await this.$store.dispatch("FETCH_WALLET_DETAILS");
      this.walletDetails = (this.$store.getters["GET_WALLET_DETAILS"] || {})[
        "data"
      ];
      // if (!this.walletDetails) {

      //   console.log(this.walletDetails);
      // }
    },
  },
};
</script>

<style lang="postcss">
.g-gray {
  background: rgba(230, 227, 227, 0.55);
}
.default-style {
  @apply border border-blue-600 text-blue-700 rounded-lg
}
.gray-style {
  @apply border text-blue-700 bg-gray-400 rounded-lg
}
.outcome {
  font-size: 11px;
  /* position: absolute; */
  bottom: 5px;
  @apply px-2 h-10;
}
</style>
